import { useEffect, useState } from "react";
import PortfolioList from "../portfolioList/PortfolioList";
import "./portfolio.scss";
import { db } from "../../firebase";

export default function Portfolio() {
  const [selected, setSelected] = useState("featured");
  const [data, setData] = useState([]);
  const list = [
    { id: "featured", title: "Featured" },
    { id: "web", title: "Web App" },
    { id: "ds", title: "Data Science" },
    { id: "mobile", title: "Mobile App" },
  ];

  const getInfo = async (i) => {
    const response = db.collection(i);
    response.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setData(items);
    });
  };

  useEffect(() => {
    switch (selected) {
      case "featured":
        getInfo("featuredPortfolio");
        break;
      case "web":
        getInfo("webPortfolio");
        break;
      case "ds":
        getInfo("dsPortfolio");
        break;
      case "mobile":
        getInfo("mobilePortfolio");
        break;
      default:
        getInfo("featuredPortfolio");
    }
  }, [selected]);

  return (
    <div className="portfolio" id="portfolio">
      <h1>Portfolio</h1>
      <ul>
        {list.map((item) => (
          <PortfolioList
            id={item.id}
            title={item.title}
            active={selected === item.id}
            setSelected={setSelected}
          />
        ))}
      </ul>
      <div className="container">
        {data.map((d) => (
          <div className="item" key={d.id}>
            <img src={d.img} alt="" />
            <h3>{d.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}
