import firebase from "firebase/app";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

firebase.initializeApp({
  apiKey: "AIzaSyD0daDfUYVzFbQbjq597ogaQ__G-gXP2ts",
  authDomain: "portfolio-f3bd3.firebaseapp.com",
  databaseURL: "https://portfolio-f3bd3-default-rtdb.firebaseio.com",
  projectId: "portfolio-f3bd3",
  storageBucket: "portfolio-f3bd3.appspot.com",
  messagingSenderId: "1061366165093",
  appId: "1:1061366165093:web:6e970e5c441fede67ce08c",
  measurementId: "G-B1EBMLRLMB",
});

const db = firebase.firestore();

export { db };
